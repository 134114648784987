footer {
    margin-bottom: 1rem;
}

footer ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .75rem;
    padding: 0 1rem;
    list-style: none;
}