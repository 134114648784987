/* Import styles */
@import-normalize;
@import './Styles/colors.css';

/* Global */
* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
        "slnt" 0;
    background-color: var(--bg);
    color: var(--main);
}

.container {
    margin: 0 auto;
    max-width: 48rem;
    padding: 1rem;
}

/* General Font/Text Styling */
h1,
h2 {
    color: var(--title);
}

h2 {
    font-size: 1.35rem;
}

p {
    line-height: 1.5;
}

a {
    color: var(--href);
    text-decoration: none;
}

a:hover {
    color: var(--hover);
}

.sm-text {
    font-size: .9rem;
}

.underline:hover {
    text-decoration: underline;
}

/* General Image Styling */
img {
    width: 100%;
}

.ext-link-svg {
    height: .9rem;
    width: auto;
    fill: var(--href);
}

/* Section Divider */
.section-break {
    border-bottom: 1px solid var(--accent);
    margin: 1.5rem 0;
}