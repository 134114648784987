.project {
    display: flex;
    margin-top: 2rem;
}

.project-left {
    border-right: 1px solid var(--accent);
    margin-right: 1rem;
    padding-right: 1rem;
    max-height: 50px;
}

.project-left .project-year {
    font-weight: 600;
    margin-top: 0;
    line-height: 1.35;
}

.project p {
    margin: 1rem 0;
}

.project-links {
    list-style: none;
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
}